<template>
  <div class="dropdown-wrapper" :class="{ error: error }">
    <div
      class="menu"
      ref="dropdown"
      :class="{ active: expanded }"
      v-on:click="expand()"
    >
      <font-awesome-icon icon="angle-down" class="angle-icon" />
    </div>
    <div class="options" ref="options" :class="{ expanded: sendClick }">
      <div
        class="options-inner"
        :class="{ expanded: expanded }"
        v-on:click="close"
      >
        <div
          v-for="(option, index) in options"
          :key="index"
          class="option-wrapper"
        >
          <div v-if="option.dummy" class="option inactive"></div>
          <div
            v-else-if="typeof option.icon !== `string`"
            class="option"
            @click="$emit('redirect', option.value)"
          >
            <font-awesome-icon class="icon" :icon="option.icon" />
            <div class="option-label">{{ option.label }}</div>
          </div>
          <div
            v-else-if="
              option.inactive &&
                option.type !== 'danger' &&
                option.type !== `postpone` &&
                option.type !== 'cancel'
            "
            class="option inactive"
          >
            <img
              class="content-image"
              :src="require(`@/images/eventsicons/${option.icon}`)"
              :alt="option.label"
            />
            <div class="option-label">{{ option.label }}</div>
            <!-- <span class="shadow"></span> -->
          </div>
          <router-link
            v-else-if="option.type == `1`"
            class="option 1"
            :to="`${option.link}/${eventUrl}`"
          >
            <!-- <font-awesome-icon class="option-icon" :icon="option.icon" /> -->
            <!-- <img
              :src="`${'@/images/eventsicons/icon-code.png'}`"
              alt=""
              srcset=""
            /> -->
            <img
              class="content-image"
              :src="require(`@/images/eventsicons/${option.icon}`)"
              :alt="option.label"
            />
            <div class="option-label">{{ option.label }}</div>
          </router-link>
          <div
            v-else-if="option.type == 'external'"
            class="option redirect"
            @click="$emit('redirect', option.value)"
          >
            <img
              class="content-image"
              :src="require(`@/images/eventsicons/${option.icon}`)"
              :alt="option.label"
            />
            <div class="option-label">{{ option.label }}</div>
          </div>
          <div
            v-if="
              option.type == `danger` && !option.inactive && !deactivateEligible
            "
            class="mask"
          ></div>
          <div
            v-if="option.type == `danger` && !option.inactive"
            class="option-danger"
            :id="option.label"
            v-on:click="optionDangerFunc(option.label)"
          >
            <img
              class="content-image"
              :src="require(`@/images/eventsicons/${option.icon}`)"
              :alt="option.label"
            />
            <div
              class="option-label"
              v-if="eventActive == true || eventActive == undefined"
            >
              Deactivate
            </div>
            <div class="option-label" v-else>Activate</div>
          </div>
          <!-- POSTPONE START-->
          <div
            v-if="option.type == `postpone` && !option.inactive"
            class="option-danger"
            :id="option.label"
            v-on:click="postponeFunc(option.label)"
          >
            <img
              class="content-image"
              :src="require(`@/images/icons/icon-stopwatch-white.png`)"
              :alt="option.label"
            />
            <div class="option-label" v-if="!eventPostponed">Postpone</div>
            <div class="option-label" v-else>Resume</div>
          </div>
          <!-- POSTPONE END-->
          <div
            v-if="option.type == `cancel` && !option.inactive && !cancelled"
            class="option-cancel"
            v-on:click="$emit('cancel', eventUrl)"
          >
            <img
              class="content-image"
              :src="require(`@/images/eventsicons/${option.icon}`)"
              alt="Cancel"
            />
            <div class="option-label">{{ option.label }}</div>
          </div>
          <div
            v-else-if="option.type == `cancel` && !option.inactive && cancelled"
            class="option-cancel"
          >
            <img
              class="content-image"
              :src="require(`@/images/eventsicons/${option.icon}`)"
              alt="Cancelled"
            />
            <div class="option-label">Cancelled</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.dropdown-wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  bottom: 50px;
  pointer-events: none;

  & > * {
    pointer-events: all;
  }

  a:active,
  a:visited,
  a:hover,
  a:link {
    // color: inherit;
    display: flex;
  }

  .menu {
    position: absolute;
    top: 10px;
    right: 15px;
    height: 20px;
    transition: all 0.5s;
    &.active {
      transform: rotate(180deg);
    }
    cursor: pointer;
    .angle-icon {
      // position: absolute;
      color: #828282;
      font-size: 22px;
    }
  }
  // end menu

  .options {
    position: absolute;
    top: 37px;
    z-index: 3;
    width: 100%;

    &.expanded {
      z-index: 5;
    }
    .option-wrapper:nth-child(odd) .option,
    .option-danger {
      border-right: 1px var(--event-option-br) solid;
      border-bottom: 1px var(--event-option-br) solid;
    }
    .option-wrapper:nth-child(even) .option,
    .option-cancel {
      // width: calc(50% - 1px) !important;
      // border-right: 1px #000 solid;
      border-bottom: 1px var(--event-option-br) solid;
      border-right: 1px var(--event-option-br) solid;
    }
    .options-inner {
      position: absolute;
      display: flex;
      flex-wrap: wrap;
      z-index: 3;
      top: 10px;
      right: -40px;
      width: 130%;
      // height: 260px;
      background-image: var(--options-background);

      // display: none;
      max-height: 0px;
      color: #a4a4a4;
      overflow: hidden;
      border-radius: 5px;
      transition: 0.4s;

      &.expanded {
        // display: flex;
        margin-top: 10px;
        border: 1px solid var(--event-option-br);
        z-index: 5;
        max-height: 1000px;
        box-shadow: var(--dashboard-shadow);
      }

      .option-wrapper {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        // height: 30px;

        .mask {
          position: absolute;
          z-index: 100;
          width: 100%;
          height: 100%;
          // padding: 10px;
          background-color: rgba(63, 63, 63, 0.637);
        }
        .option-cancel,
        .option-danger {
          border-bottom: 0;
        }
        .option,
        .option-danger,
        .option-cancel {
          position: relative;
          padding: 7px 8px;
          display: flex;
          font-size: 11px;
          transition: 0.4s;
          width: 100%;
          // height: 18px;
          margin: 0;
          cursor: pointer;
          align-items: center;
          color: var(--text);

          &:hover:not(.inactive, .option-cancel, .option-danger) {
            padding-left: 16px;
            // padding-right: 12px;
            background-color: var(--hover-event);
          }

          &::after {
            content: "";
            margin: auto;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 95%;
            border-bottom: 1px solid #454545;
          }

          &:last-child::after {
            border-bottom: none;
          }

          .option-icon {
            position: absolute;
            left: 18px;
            top: 13px;
            color: rgb(79, 167, 79);
          }
          // end option-icon

          .icon {
            color: var(--primary);
            font-size: 16px;
            width: 20px;
            margin-right: 8px;
          }

          &.inactive {
            color: var(--inactive-text);
            cursor: not-allowed;
            filter: grayscale(1);
            // opacity: 0.2;
            .option-icon {
              color: #555;
            }

            // &:hover {
            //   padding-left: initial;
            //   // padding-right: 12px;
            //   background-color: initial;
            // }
          }

          img {
            margin-right: 8px;
            width: 15px;
            // height: max-content;
          }
        }
        // end option, option-danger, option-cancel

        .option-danger,
        .option-cancel {
          background: linear-gradient(
            180deg,
            rgba(255, 0, 0, 1) 0%,
            rgba(153, 0, 0, 1) 100%
          );
          // height: 18px;
          color: white;

          &:hover {
            padding-left: 16px;
            padding-right: 12px;
            // background-color: rgb(148, 30, 30) !important;
          }

          .option-icon {
            color: rgb(79, 167, 79);
          }
          // end option-icon
        }
        // end option-danger, option-cancel

        .option-cancel {
          .option-icon {
            color: red;
          }
          // end option-icon
        }
        // end option-cancel
      }
      // end option-wrapper
    }
    // end option-inner
  }
  // end option
}
// end dropdown-wrapper
</style>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChartBar,
  faCogs,
  faClipboard,
  faCopy,
  faThumbsUp,
  faEnvelope,
  faEdit,
  faPowerOff,
  faCode,
  faMoneyBill,
  faPrint,
  faUserFriends,
  faPercent,
  faTicketAlt,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faChartBar,
  faCogs,
  faClipboard,
  faCopy,
  faThumbsUp,
  faEnvelope,
  faEdit,
  faPowerOff,
  faCode,
  faMoneyBill,
  faPrint,
  faUserFriends,
  faPercent,
  faTicketAlt,
  faTimesCircle
);

export default {
  name: "event-options",
  components: {
    FontAwesomeIcon
  },
  props: {
    label: String,
    placeholder: String,
    type: String,
    options: Array,
    required: Boolean,
    error: Boolean,
    val: String,
    name: String,
    date: String,
    image: String,
    venue: String,
    url: String,
    link: String,
    eventUrl: String,
    postponed: Boolean,
    activated: Boolean,
    cancelled: Boolean,
    deactivateEligible: Boolean,
    clicked: Boolean,
    emitClose: { type: Boolean, default: false },
    cloneable: Boolean,
    icon: Array
  },
  data() {
    return {
      expanded: false,
      mobile: false,
      value: null,
      routeUrl: "",
      tempActive: false,
      tempPostponed: false
    };
  },
  computed: {
    eventActive: {
      get: function() {
        return this.tempActive;
      },
      set: function(input) {
        this.tempActive = input;
      }
    },
    eventPostponed: {
      get: function() {
        return this.tempPostponed;
      },
      set: function(input) {
        this.tempPostponed = input;
      }
    },
    sendClick: {
      get: function() {
        return this.expanded;
      },
      set: function(val) {
        this.expanded = val;
      }
    }
  },
  watch: {
    clicked(val) {
      this.sendClick = val;
    }
  },
  methods: {
    expand() {
      if (this.expanded) {
        this.close();
      } else {
        this.expanded = true;
      }
    },
    close() {
      let dropdown = this.$refs.dropdown;
      let options = this.$refs.options;

      if (dropdown) {
        dropdown.style.zIndex = 6;

        setTimeout(() => {
          dropdown.removeAttribute("style");
        }, 400);
      }

      if (options) {
        options.style.zIndex = 5;

        setTimeout(() => {
          options.removeAttribute("style");
        }, 400);
      }

      this.expanded = false;
    },
    optionDangerFunc(id) {
      let self = this;
      if (id == "Activate/Deactivate") {
        if (this.eventActive == true || this.eventActive == undefined) {
          console.log("deactivate!");
          console.log(this.eventUrl);
          this.$axios
            .post("/events/deactivate", {
              url: self.eventUrl
            })
            .then(response => {

              this.eventActive = false;
              this.$toast("Event deactivated!", { type: "success" });
            })
            .catch(error => console.log(error));
        } else {
          console.log("activate!");
          this.$axios
            .post("/events/activate", {
              url: self.eventUrl
            })
            .then(response => {
              this.eventActive = true;
              this.$toast("Event activated!", { type: "success" });
            })
            .catch(error => console.log(error));
        }
      }
    },
    postponeFunc(id) {
      let self = this;
      if (id == "Postpone/Resume") {
        if (!this.eventPostponed == true || this.eventPostponed == undefined) {
          console.log(this.eventUrl);
          this.$axios
            .post("/events/postpone", {
              url: self.eventUrl
            })
            .then(response => {
              console.log(response.data);
              this.eventPostponed = true;
            })
            .catch(error => console.log(error));
        } else {
          this.$axios
            .post("/events/resume", {
              url: self.eventUrl
            })
            .then(response => {
              console.log(response.data);
              this.eventPostponed = false;
            })
            .catch(error => console.log(error));
        }
      }
    }
  },

  mounted() {
    this.eventActive = this.activated;
    this.eventPostponed = this.postponed;

    let OS = window.navigator.userAgent;

    if (OS.indexOf("iPhone") !== -1) this.mobile = true;

    if (OS.indexOf("Android") !== -1) this.mobile = true;

    if (!this.emitClose) {
      this.onClickBody = e => {
        if (this.expanded && !this.$el.contains(e.target)) {
          this.close();
        }
      };
    }

    document.body.addEventListener("click", this.onClickBody);
  },
  destroyed() {
    document.body.removeEventListener("click", this.onClickBody);
  }
};
</script>
